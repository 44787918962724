'use client'

import {
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { find } from 'lodash'
import classNames from 'classnames'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { AdsPlacementModel } from 'types/models'
import useDebounce from 'hooks/useDebounce'
import { clientSideMetrics } from '@marketplace-web/shared/metrics'

import AdsContext from '../../containers/AdsProvider/AdsContext'
import { AdShape, AdPage } from '../../constants'

import AdContent from './AdContent'
import AdPlaceholder from './AdPlaceholder'
import AdErrorBoundary from './AdErrorBoundary'
import useStickyOptions from './useStickyOptions'
import AdMock from './AdMock'
import { getAdPlacementId } from './utils'
import useShouldRenderAd from './useShouldRenderAd'
import AdManager from '../../utils/ad-manager'

type Props = {
  id?: string
  shape: AdShape
  mediation?: string | null
  config?: AdsPlacementModel
  isManuallyRendered?: boolean
  isManuallyRefreshed?: boolean
  isSidebarAd?: boolean
  renderFallback?: () => ReactNode
}

const Advertisement = ({
  id: propsId,
  shape,
  mediation = null,
  config,
  isSidebarAd = false,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
  renderFallback,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isAdRendered, setIsAdRendered] = useState<boolean>(false)
  const [isFallbackShown, setIsFallbackShown] = useState<boolean>(false)
  const shouldRenderAd = useShouldRenderAd(shape)
  const hasFallback = Boolean(renderFallback)

  const updateFallbackVisibility = useDebounce(
    (isAdVisible: boolean) => {
      const shouldShowFallback = !isAdVisible

      if (isFallbackShown && !shouldShowFallback)
        clientSideMetrics.counter('ad_fallback_hide_attempt').increment()

      // If fallback is already shown, we don't want to start showing the ad later
      if (!isFallbackShown && shouldShowFallback) {
        setIsFallbackShown(shouldShowFallback)
      }
    },
    1500,
    false,
  )

  const { shouldMockAds, placements } = useContext(AdsContext)
  const placement = find(placements, { shape, mediation })

  // TODO: Rework this when we have real Van ads
  // For now it will be used only under the condition
  // that web_ads_van_placement feature switch is enabled
  const shouldShowVanAd = useFeatureSwitch('web_ads_van_placement')
  const vanPlacement = find(placements, { shape, mediation: 'van' })

  const placementConfig = useMemo(
    () => config || (shouldShowVanAd && vanPlacement) || placement,
    [config, placement, vanPlacement, shouldShowVanAd],
  )

  const id = useMemo(() => propsId || AdManager.generatePlacementId(), [propsId])

  const stickyOptions = useStickyOptions({
    isSticky: placementConfig?.options.isSticky,
  })

  const handleAdRender = useCallback(
    (isAdVisible: boolean) => {
      setIsAdRendered(isAdVisible)
      if (hasFallback) updateFallbackVisibility(isAdVisible)
    },
    [updateFallbackVisibility, hasFallback],
  )

  const hideAd = !placementConfig || !shouldRenderAd || (isFallbackShown && hasFallback)

  useEffect(() => {
    if (hideAd && hasFallback) {
      clientSideMetrics.counter('ad_fallback_show').increment()
    }
  }, [hideAd, hasFallback])

  if (hideAd) return renderFallback?.()

  return (
    <Suspense>
      <AdErrorBoundary
        pageName={placementConfig.page || AdPage.Unknown}
        placementId={getAdPlacementId(placementConfig)}
      >
        {shouldMockAds ? (
          <AdMock shape={shape} isSidebarAd={isSidebarAd} stickyOptions={stickyOptions} />
        ) : (
          <div
            className={classNames(
              'ad-container',
              `ad-container--${shape}`,
              !!stickyOptions && 'ad-sticky',
              isAdRendered && 'ad-container--rendered',
              isSidebarAd && 'ad-sidebar',
            )}
            data-testid="advertisement"
            style={{ top: stickyOptions?.offset }}
            ref={ref}
            suppressHydrationWarning
          >
            <AdPlaceholder shape={shape} platform={placementConfig.platform} />
            <AdContent
              id={id}
              placementConfig={placementConfig}
              isManuallyRefreshed={isManuallyRefreshed}
              isManuallyRendered={isManuallyRendered}
              onAdRender={handleAdRender}
              isAdRendered={isAdRendered}
            />
          </div>
        )}
      </AdErrorBoundary>
    </Suspense>
  )
}

export default Advertisement
